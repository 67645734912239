import { Component, OnInit } from '@angular/core';
import { ApiService } from "../services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ModalService } from './_modal';
import { PrivacyService } from 'src/services/privacy.service';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  privacy: any;
  privac: any;
  title = 'El Clan de la Malta';
  favoritesNumber: number = 0;

  rute: any

  constructor(private api: ApiService,
    private gtmService: GoogleTagManagerService,
    private modalService: ModalService, private privacyService: PrivacyService,
    private router: Router,
    private routers: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.cargarScriptsCookies()
    const cookies = document.cookie;
    const OneTrustStorage = this.localStorageInclude('fc482041bb2d2f4b43ee11e07982dabd0c7de2aae21d3df7a093009908878b32');
    const OneTrustCookie = cookies.includes('fc482041bb2d2f4b43ee11e07982dabd0c7de2aae21d3df7a093009908878b32');
    if (OneTrustCookie || OneTrustStorage) {
      sessionStorage.setItem('ClanMaltaStorage.Age', String(true));
      sessionStorage.setItem('ClanMaltaStorage.birthdate', '');
      const urlCompleta = this.encontrarSubstring(window.location.href)
      if(urlCompleta == '/age'){
        this.router.navigate(['/']);
      }
    } else {
      this.checkToken()
    }
    this.gtmService.addGtmToDom();
    $("#menu-sitio").show();
    $("footer").show();

    $(".navbar-toggler").click(function () {
      $("#menu-sitio").toggleClass("menu-abierto");
      $('.responsive').slick({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false
      });
    });

  }

  private checkToken() {
    if (!sessionStorage.getItem('ClanMaltaStorage.Age') && !this.api.getToken()) {
      this.router.navigate(['/age']);
    }
  }

  private encontrarSubstring(cadena)
   { const subcadena = "/age";
     return cadena.includes(subcadena) ? subcadena : null; 
    }

  private localStorageInclude(value: any) {
    let include = false;
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      let _value = localStorage.getItem(key);
      if (_value === value) {
        include = true
      }
    }
    return include
  }

  private cargarScriptsCookies() { 
    // Incluir el script de consentimiento de OneTrust
     var script1 = document.createElement('script');
     script1.src = 'https://cdn.cookielaw.org/consent/019306f0-d9b2-7fb7-84f9-45df4a62e384/OtAutoBlock.js';
     script1.type = 'text/javascript'; document.head.appendChild(script1);
     var script2 = document.createElement('script');
     script2.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
     script2.charset = 'UTF-8';
     script2.setAttribute('data-domain-script', '019306f0-d9b2-7fb7-84f9-45df4a62e384');
     script2.type = 'text/javascript';
      document.head.appendChild(script2); 
      
    }

  private OptanonWrapper() { }

}
